import { FC, ChangeEvent, ReactNode } from "react";

import useA11y from "apps/website/hooks/useA11y";
import { IFormFieldSelectOptionAdvancedOptions } from "apps/website/types/form";
import {
  groupedFieldSpanObjectMap,
  OptionColumnSize,
} from "apps/website/maps/Form.map";

import {
  CheckableSelectOption,
} from "../Select/CheckableSelectOption/CheckableSelectOption";
import Grid from "../../layout/Grid/Grid";
import Column from "../../layout/Column/Column";
import { FontStyle } from "../Label/Label.map";
import Label from "../Label/Label";
import Tooltip, { ITooltip } from "../../feature/Tooltip/Tooltip";
import { IconSvg } from "../../base/Icon/Icon.map";
import { Align, Display } from "../../base/Text/Text.map";
import {
  ConfirmableSelectOption,
} from "../Select/ConfirmableSelectOption/ConfirmableSelectOption";
import {
  BasicSelectOption,
} from "../Select/BasicSelectOption/BasicSelectOption";

export type TOptionStyle = "default" | "confirmationModal" | "basic";
export interface IRadioSelectOption {
  name: string;
  value: string;
  selected?: boolean;
  advancedOptions?: IFormFieldSelectOptionAdvancedOptions;
  icon?: IconSvg;
  display?: Display;
  optionStyle?: TOptionStyle;
}

export interface IRadioSelect {
  label: string;
  name: string;
  options: IRadioSelectOption[];
  optionSize: OptionColumnSize;
  optional?: boolean;
  optionStyle?: TOptionStyle;
  hideLabel?: boolean;
  labelPosition?: Align;
  labelStyle?: FontStyle;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  component?: string;
  className?: string;
  children?: ReactNode;
  tabbable?: boolean;
  tooltip?: ITooltip;
}

const RadioSelect: FC<IRadioSelect> = ({ onChange, name, label, labelStyle, options, optionSize = "full", optional = false, className, hideLabel = false, component = "RadioSelect", tabbable = true, children, tooltip, labelPosition = "default" }) => {
  const { UUID } = useA11y();
  const aGroupMemberHasSecondaryText = !!options.find(({ advancedOptions }) => !!advancedOptions?.secondaryText);
  const aGroupMemberHasTertiaryText = !!options.find(({ advancedOptions }) => !!advancedOptions?.tertiaryText);
  const aGroupMemberHasAnIcon = !!options.find(({ icon }) => !!icon);
  return (
    <div data-component={component} className={className} data-theme="form-input">
      <Label
        label={label}
        align={labelPosition}
        fontStyle={labelStyle}
        hideLabel={hideLabel}
        UUID={UUID}
        optional={optional}
      />
      <Grid>
        <>
          { options.map((option, optionIndex) => (
            <Column key={option.value} spans={groupedFieldSpanObjectMap[optionSize]}>
              <label key={option.value} className={`relative flex flex-1 flex-wrap justify-between items-center group ${options.length > 2 && "min-w-[100%]"} ${aGroupMemberHasAnIcon && "flex-col"}`}>
                <input
                  type="radio"
                  id={UUID}
                  name={name}
                  value={option.value}
                  onChange={onChange}
                  checked={option.selected}
                  className={`absolute left-1/2 -translate-x-1/2 w-full h-full peer appearance-none outline-none cursor-pointer ${(aGroupMemberHasSecondaryText || aGroupMemberHasTertiaryText) ? "rounded-md" : "rounded-full"}`}
                  tabIndex={tabbable ? 0 : -1}
                />
                { [ "confirmationModal", "basic" ].includes(option.optionStyle || "default") ? (
                  <>
                    { option.optionStyle === "confirmationModal" && (
                      <ConfirmableSelectOption
                        name={option.name}
                        secondaryText={option.advancedOptions?.secondaryText}
                        tertiaryText={option.advancedOptions?.tertiaryText}
                        tag={option.advancedOptions?.tag}
                        current={option.advancedOptions?.current}
                      />
                    ) }
                    { option.optionStyle === "basic" && (
                      <BasicSelectOption
                        name={option.name}
                      />
                    ) }
                  </>
                ) : (
                  <CheckableSelectOption
                    name={option.name}
                    icon={option.icon}
                    optionIndex={optionIndex}
                    secondaryText={option.advancedOptions?.secondaryText}
                    tertiaryText={option.advancedOptions?.tertiaryText}
                    aGroupMemberHasSecondaryText={aGroupMemberHasSecondaryText}
                    aGroupMemberHasTertiaryText={aGroupMemberHasTertiaryText}
                    tag={option.advancedOptions?.tag}
                    display={option.display}
                  />
                ) }
              </label>
            </Column>
          ))
          }
        </>
      </Grid>
      { children }
      { tooltip && (
        <div className="h-4 pt-2">
          <Tooltip id={UUID} {...tooltip} />
        </div>
      ) }
    </div>
  );
};

export default RadioSelect;
