"use client";

import { FC, useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";

import Image, { IImageProps } from "apps/website/components/base/Image/Image";
import Text from "apps/website/components/base/Text/Text";
import Table from "apps/website/components/feature/Table/Table";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import {
  ICondensedListItemProps,
} from "apps/website/components/feature/CondensedList/CondensedListItem/CondensedListItem";
import CondensedList from "apps/website/components/feature/CondensedList/CondensedList";
import Spacer from "apps/website/components/layout/Spacer/Spacer";

import {
  TFeatureFlags,
  TPriceFramingGroup,
} from "../SummaryDetailed/SummaryDetailed";

export interface ISummaryFuture {
  headerTitle: string;
  headerBodyStruckOut?: string;
  headerBody: string;
  footerTitle: string;
  footerBodyStruckOut?: string;
  footerBody: string;
  footerExtraInfo: string;
  image: IImageProps;
  listLabel: string;
  listItems: ICondensedListItemProps[];
  multicatListItems: ICondensedListItemProps[]; // for now
}

const SummaryFuture: FC<ISummaryFuture> = ({
  headerTitle,
  headerBodyStruckOut,
  headerBody,
  footerTitle,
  footerBodyStruckOut,
  footerBody,
  footerExtraInfo,
  image,
  listLabel,
  listItems,
  multicatListItems = [],
}) => {

  const posthog = usePostHog();

  const [ priceFramingExperimentGroup, setPriceFramingExperimentGroup ] = useState<TPriceFramingGroup>("control");

  useEffect(() => {
    const featureFlags: TFeatureFlags = posthog.featureFlags.getFlagVariants() as TFeatureFlags;

    setPriceFramingExperimentGroup(featureFlags["price-framing"] || "control");

  }, [ posthog.featureFlags ]);

  return (
    <div data-component={SummaryFuture.name}>
      { image?.src && (
        <Image image={image} alt={headerTitle} />
      ) }
      <Table className={"w-full border-collapse bg-white"}>
        <tr>
          <th
            className={`text-left px-4 py-2 ${themeRootClassMap.dark}`}
            data-theme="dark"
            scope="col"
            colSpan={3}
          >
            <div className="flex items-center justify-between">
              <Text tag="span" display="subtitle" size={legacySizeCollectionMap.bodyLg}>{ headerTitle }</Text>
              <div>
                { headerBodyStruckOut && (<><Text tag="s">{ headerBodyStruckOut }</Text><br /></>) }
                <Text tag="span" display="subtitle" size={legacySizeCollectionMap.bodyLg}>{ headerBody }</Text>
              </div>
            </div>
          </th>
        </tr>
        <tr className="h-auto">
          <td className="text-left px-4 pb-4 flex flex-col" colSpan={3}>
            <Spacer size="md" />
            { /** temporarily hardcoded for multicat discount test */ }
            { footerBodyStruckOut && <Text size={legacySizeCollectionMap.bodySm}>
              { listLabel }  with 5% ongoing multicat discount.</Text> }
            { !footerBodyStruckOut && <Text
              size={priceFramingExperimentGroup === "test" ? legacySizeCollectionMap.bodyLg : legacySizeCollectionMap.bodySm}
              display={priceFramingExperimentGroup === "test" ? "subtitle" : "default"}
            >{ listLabel }</Text> }
            <Spacer size="md" />
            <CondensedList items={[ ...multicatListItems, ...listItems ]} imageSize="sm" />
          </td>
        </tr>

        <tr data-theme={priceFramingExperimentGroup === "test" ? "green" : "light"} className={themeRootClassMap[priceFramingExperimentGroup === "test" ? "green" : "light"]}>
          <td colSpan={3} className={priceFramingExperimentGroup === "test" ? "pt-1" : ""}>
            <th className="text-left pl-4 w-[66.666%] inline-block">
              <Text tag="span" display="subtitle" size={legacySizeCollectionMap.bodyLg}>{ footerTitle }</Text>
            </th>
            <td className={`text-right pr-4 w-[33.333%] inline-block ${priceFramingExperimentGroup === "test" ? "pt-2" : ""}`}>
              { footerBodyStruckOut && (<Text tag="s">{ footerBodyStruckOut }<br /></Text>) }
              <Text tag="span" display="subtitle" size={legacySizeCollectionMap.bodyLg}>{ footerBody }</Text>
            </td>
          </td>
        </tr>
        <tr data-theme={priceFramingExperimentGroup === "test" ? "green" : "light"} className={themeRootClassMap[priceFramingExperimentGroup === "test" ? "green" : "light"]}>
          <td colSpan={3} className="p-2">
            <Text align="right" size={legacySizeCollectionMap.bodySm}>{ footerExtraInfo }</Text>
          </td>
        </tr>

      </Table>
    </div>
  );
};

export default SummaryFuture;
