"use client";

import {
  FC,
  useEffect,
  useMemo,
  useState,
} from "react";
import dayjs from "dayjs";
import { useRouter, useSearchParams } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { useCookies } from "react-cookie";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Section from "apps/website/components/layout/Section/Section";
import Container from "apps/website/components/layout/Container/Container";
import Text from "apps/website/components/base/Text/Text";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import Grid from "apps/website/components/layout/Grid/Grid";
import Column from "apps/website/components/layout/Column/Column";
import ImageCarousel from "apps/website/components/feature/Carousel/ImageCarousel/ImageCarousel";
import Button from "apps/website/components/base/Button/Button";
import Tag from "apps/website/components/base/Tag/Tag";
import Icon from "apps/website/components/base/Icon/Icon";
import { DisplayState } from "@/constants/state";
import { FormsActionsAPI } from "apps/website/api/FormsActions";
import { PublicConfig } from "apps/website/config/public";
import { KatKinProduct } from "@forms/schema";
import {
  getFlowFieldValuesForAction,
} from "libs/state/src/lib/stores/useFormServiceStore";
import {
  IPossibleDeliveryDates,
} from "libs/api/customer/src/lib/types/delivery-dates";
import { useAuth } from "@auth/client-sdk-react";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

import {
  IOneClickPurchaseCard,
} from "../SectionOneClickCard/SectionOneClickCard";
import Image from "../../base/Image/Image";
import {
  getActionTrackingInfo,
} from "../../../utils/tracking/getActionTrackingInfo";

export interface ISectionOneClickPurchase {
  title?: string;
  card: IOneClickPurchaseCard;
  continueToPath: string;
  noThanksPath?: string;
  theme?: Theme;
  product?: KatKinProduct;
  params?: Record<string, string>;
  hideBannerIfProductNotInBasket?: boolean;
}

const SectionOneClickPurchase: FC<ISectionOneClickPurchase> = ({ title, card, theme = "light-grey", continueToPath, noThanksPath, params, product, hideBannerIfProductNotInBasket = false }) => {

  const { accessToken } = useAuth();
  const router = useRouter();
  const searchParams = useSearchParams();
  const { chainQueryParamsToUrl } = useQueryParams();
  const posthog = usePostHog();
  const [ cookies ] = useCookies([ "_fbp", "_fbc" ]);

  const formsActionsAPI = useMemo(() => new FormsActionsAPI(
    PublicConfig.NEXT_PUBLIC_FORM_ACTIONS_URL,
    accessToken,
  ), [ accessToken ]);

  const [ isProductAdded, setIsProductAdded ] = useState(false);
  const [ deliveryDate, setDeliveryDate ] = useState<string | null>(null);
  const [ checkoutState, setCheckoutState ] = useState<DisplayState>(DisplayState.READY);

  const onAddProduct = () => {
    setIsProductAdded(true);
  };
  const onRemoveProduct = () => {
    setIsProductAdded(false);
  };

  const checkoutWithProductAndContinue = async () => {
    setCheckoutState(DisplayState.PROCESSING);

    try {
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const fieldSubmitValuesMap = getFlowFieldValuesForAction("");
      if (params?.discountCode) {
        fieldSubmitValuesMap.set("discountCode", { value: params.discountCode });
      }
      if (params?.cadence) {
        fieldSubmitValuesMap.set("cadence", { value: params.cadence });
      }
      if (params?.quantity) {
        fieldSubmitValuesMap.set("quantity", { value: params.quantity });
      }
      if (deliveryDate) {
        fieldSubmitValuesMap.set("deliveryDate", { value: deliveryDate });
      }
      const result = await formsActionsAPI.performAction("checkoutLitter", fieldSubmitValuesMap, product ?? "UNKNOWN", window.location.pathname, undefined, featureFlags, getActionTrackingInfo(cookies));
      if (result.success) {

        await router.push(chainQueryParamsToUrl(continueToPath, {
          allCatNames: searchParams.get("allCatNames") ?? undefined,
          allCatGenders: searchParams.get("allCatGenders") ?? undefined,
          deliveryDate: searchParams.get("deliveryDate") ? dayjs(searchParams.get("deliveryDate") as string).format("YYYY-MM-DD") : undefined,
          litterDeliveryDate: dayjs(deliveryDate).format("YYYY-MM-DD"),
          katkinCustomerId: searchParams.get("katkinCustomerId") ?? undefined,
          signUpRoute: searchParams.get("signUpRoute") ?? undefined,
        }));
        setCheckoutState(DisplayState.COMPLETE);
      }
    } catch {
      console.error("Couldn't checkout");
    }
    setCheckoutState(DisplayState.ERROR);
  };

  const continueWithoutProduct = async () => {
    await router.push(chainQueryParamsToUrl(noThanksPath || continueToPath, {
      allCatNames: searchParams.get("allCatNames") ?? undefined,
      allCatGenders: searchParams.get("allCatGenders") ?? undefined,
      deliveryDate: searchParams.get("deliveryDate") ? dayjs(searchParams.get("deliveryDate") as string).format("YYYY-MM-DD") : undefined,
      katkinCustomerId: searchParams.get("katkinCustomerId") ?? undefined,
      signUpRoute: searchParams.get("signUpRoute") ?? undefined,
    }));
  };

  const getNextAvailableDeliveryDate = async (): Promise<void> => {
    try {
      const featureFlags = posthog.featureFlags.getFlagVariants();
      const deliveryDatesResponse = await formsActionsAPI.performAction("getAvailableLitterTrialDeliveryDates", new Map(), product ?? "UNKNOWN", undefined, undefined, featureFlags, getActionTrackingInfo(cookies));
      if (deliveryDatesResponse.success) {
        if (searchParams.get("deliveryDate") === "string") {
          const queryParamDate = dayjs(searchParams.get("deliveryDate"));
          const possDeliveryDates = deliveryDatesResponse.responseBody as IPossibleDeliveryDates;
          const sameDeliveryDate = possDeliveryDates.dates.find(
            (date: { date: string, price: number }) => (queryParamDate.isSame(date.date, "day")),
          )?.date;
          if (!sameDeliveryDate) {
            const closestDeliveryDate = possDeliveryDates.dates.find(
              (date: { date: string, price: number }) => (queryParamDate < dayjs(date.date) && date.price === 0),
            )?.date;
            if (!closestDeliveryDate) {
              const nextAvailableTrialDate = possDeliveryDates?.dates?.find(
                (date: { date: string, price: number }) => date.price === 0,
              )?.date;
              if (nextAvailableTrialDate) {
                setDeliveryDate(nextAvailableTrialDate);
              }
            } else {
              setDeliveryDate(closestDeliveryDate);
            }
          } else {
            setDeliveryDate(sameDeliveryDate);
          }
        } else {
          const possDeliveryDates = deliveryDatesResponse.responseBody as IPossibleDeliveryDates;
          const nextAvailableTrialDate = possDeliveryDates.dates.find(
            (date: { date: string, price: number }) => date.price === 0,
          )?.date;
          if (nextAvailableTrialDate) {
            setDeliveryDate(nextAvailableTrialDate);
          }
        }
      } else {
        console.error("Couldn't find delivery date", deliveryDatesResponse);
      }
    } catch (error) {
      console.error("Couldn't find delivery date", error);
    }
  };

  const formatDeliveryDate = (dateString: string) => dayjs(dateString).format("ddd MMM D YYYY");

  useEffect(() => {
    void getNextAvailableDeliveryDate();
  }, [ searchParams ]);

  useEffect(() => {
    void router.prefetch(continueToPath);
  }, []);

  return (
    <>
      <Section component={SectionOneClickPurchase.name} size={ { top: "md-alt", bottom: "md-alt" } } theme={theme}>
        <Container>
          { title && (
            <>
              <Text tag="h2" display="subtitle" size={legacySizeCollectionMap.titleMd} align="center">
                { title }
              </Text>
              <Spacer size="md-alt" />
            </>
          ) }
          <Grid>
            <Column data-testid="card" spans={{ md: 6, "2xl": 4 }} offset={{ md: 4, "2xl": 5 }} className="bg-white">
              <div className="relative z-20">
                <ImageCarousel images={card.images} />
                { card.tag && (
                  <Tag theme="pink" className="absolute top-4 left-4 z-40" whitespace="preWrap">{ card.tag }</Tag>
                ) }
              </div>
              <div className="p-4">
                <Grid gap="lg">
                  <Column>
                    <Text tag="h2" display="title" size={legacySizeCollectionMap.titleSm} align="center" data-testid="cardTitle">
                      { card.title }
                    </Text>
                    { card.icons && (
                      <>
                        <Spacer size="md" />
                        <Grid>
                          { card.icons.map((icon) => (
                            <Column key={icon.title} spans={3} align="center">
                              <div className="max-w-[50px]">
                                <Image image={icon.image} alt={icon.title} />
                              </div>
                              <Spacer size="sm" />
                              <Text display="subtitle" align="center" size={{ default: "3xs", md: "2xs" }}>{ icon.title }</Text>
                            </Column>
                          )) }
                        </Grid>
                      </>
                    ) }
                    <Spacer size="lg" />
                    <div className="flex justify-center items-center">
                      <Text size={legacySizeCollectionMap.bodySm} align="center" className="flex justify-center items-center">
                        <span data-testid="cardPricePrefix">
                          { card.pricePrefix }
                        </span>
                        { card.price && (
                          <>
                          &nbsp;{ card.salePrice ? (<s data-testid="cardPrice">{ card.price }</s>) : card.price }
                          </>
                        ) }
                        { card.salePrice && (
                          <>
                          &nbsp;<Text tag="span" display="subtitle" data-testid="cardSalePrice">{ card.salePrice }</Text>
                          </>
                        ) }
                      </Text>
                      { card.tag && (
                        <Tag theme="pink" className="max-w-[75px] ml-2" whitespace="preWrap" size="small">{ card.tag }</Tag>
                      ) }
                    </div>
                    <Spacer size="lg" />
                    <Column align="center" data-testid="cardAddProductWrapper">
                      { isProductAdded ? (
                        <div className="bg-green px-4 h-[50px] flex justify-center items-center space-x-4">
                          <Icon icon="tick" size="small"/>
                          <Text display="title">Added</Text>
                        </div>
                      ) : (
                        <Button onClick={onAddProduct} disabled={!deliveryDate} data-testid="cardAddCta">
                          { card.addCtaText }
                        </Button>
                      ) }
                    </Column>
                    <Spacer size="lg" />
                    <Grid>
                      <Column spans={6}>
                        <Text size="2xs" className="flex items-center justify-center">
                      First delivery:&nbsp;
                          <Text tag="span" display="subtitle" size={legacySizeCollectionMap.titleXs} data-testid="cardDeliveryDate">
                            { deliveryDate && formatDeliveryDate(deliveryDate) }
                          </Text>
                        </Text>
                      </Column>
                      <Column spans={6}>
                        <Text size="2xs" className="flex items-center justify-center">After that:&nbsp;
                          <Text tag="span" display="subtitle" size={legacySizeCollectionMap.titleXs} data-testid="cardSubscriptionDetails">
                            { card.subscriptionDetails }
                          </Text>
                        </Text>
                      </Column>
                    </Grid>
                    <Spacer size="md" />
                    <Text tag="div" size="2xs" align="center" data-testid="cardFooter">
                      { card.footer }
                    </Text>
                    <Spacer size="md" />
                    <Column align="center">
                      { isProductAdded ? (
                        <Button design="underline" data-testid="cardRemoveCta" onClick={onRemoveProduct}>
                          { card.removeCtaText || "Remove" }
                        </Button>
                      ) : (
                        <Button design="underline" data-testid="cardContinueWithoutOneClickPurchase" onClick={continueWithoutProduct}>
                          { card.continueWithoutProductCtaText || "No Thanks" }
                        </Button>
                      ) }
                    </Column>
                  </Column>
                </Grid>
              </div>
            </Column>
          </Grid>
        </Container>
      </Section>
      { (!hideBannerIfProductNotInBasket || isProductAdded) && (
        <div data-theme="brand" className={`fixed bottom-0 left-0 w-full p-4 z-40 ${themeRootClassMap.brand}`}>
          <Container>
            <Grid>
              <Column align="center" justify="center">
                { isProductAdded ? (
                  <Button color="secondary" onClick={checkoutWithProductAndContinue} state={checkoutState} disabled={!deliveryDate} data-testid="continueWithOneClickPurchase">Place order</Button>
                ) : (
                  <Button onClick={continueWithoutProduct} design="underline" data-testid="continueWithoutOneClickPurchase">Continue without trial offer</Button>
                ) }
              </Column>
            </Grid>
          </Container>
        </div>
      ) }
    </>
  );
};

export default SectionOneClickPurchase;
