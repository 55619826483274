import { FC } from "react";

import List from "apps/website/components/base/List/List";
import {
  ListColumns,
  AvailableTag,
  BreakpointListColumns,
} from "apps/website/components/base/List/List.map";

import CondensedListItem, {
  ICondensedListItemProps,
} from "./CondensedListItem/CondensedListItem";
import { ImageSize } from "./CondensedListItem/CondensedListItem.map";

export interface ICondensedListProps {
  items: ICondensedListItemProps[];
  columns?: ListColumns | BreakpointListColumns;
  listType?: AvailableTag
  imageSize?: ImageSize;
}

const CondensedList: FC<ICondensedListProps> = ({ items, listType = "ul", columns = "default", imageSize }) => (
  <List component={CondensedList.name} tag={listType} columns={columns} className="space-y-4">
    { items.map((item, index) => (
      <CondensedListItem key={`condensed-list-item-${index}`} imageSize={imageSize} {...item} title={listType === "ol" ? `${index + 1}. ${item.title}` : item.title}/>
    )) }
  </List>
);

export default CondensedList;
