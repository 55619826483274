import { FC, ReactNode, memo } from "react";

import Text from "../Text";
import { Align, AvailableTag, Color } from "../Text.map";

import { bodySizeMap, TBodySize } from "./TextBody.map";

export interface IBodyProps {
  id?: string;
  tag?: AvailableTag;
  size?: TBodySize
  align?: Align;
  color?: Color;
  children: ReactNode;
}

const TextBody: FC<IBodyProps> = ({ id, size = 1, align, color, tag = "p", children }) => (
  <Text
    id={id}
    size={bodySizeMap[size]}
    align={align}
    tag={tag}
    color={color}
  >
    { children }
  </Text>
);

export default memo(TextBody);
