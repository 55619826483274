"use client";

import { FC, useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";

import { IStoryBlokComponent } from "apps/website/components/page/api.types";
import { useAuth } from "@auth/client-sdk-react";
import { CustomerAPI } from "@api-clients/customer";
import { PublicConfig } from "apps/website/config/public";
import {
  IMySubscriptions,
} from "libs/api/customer/src/lib/types/subscriptions";

import SectionWarningCard from "../../section/SectionWarningCard/SectionWarningCard";
import SectionCheckboxEnabledCTA from "../../section/SectionCheckboxEnabledCTA/SectionCheckboxEnabledCTA";
import {
  TPrecancellationSubscriptionState,
} from "../../section/SectionSplash/SectionPreCancellationSplash/SectionPreCancellationSplash";

export interface IStoryBlokWarningCard {
  title: string;
  subscriptionState?: TPrecancellationSubscriptionState;
}

interface IStoryBlokWarningBlock extends IStoryBlokWarningCard {
  accessToken?: string;
  subscriptionId: string | null;
}

const WarningBlock: FC<IStoryBlokWarningBlock> = ({ title, subscriptionState, accessToken, subscriptionId }) => {

  const [ userSubscriptions, setUserSubscriptions ] = useState<IMySubscriptions>();

  useEffect(() => {
    if (accessToken) {
      const effectSetSubscriptions = async () => {
        // useAPI with .Customer returns 401?
        const customer = new CustomerAPI(
          PublicConfig.NEXT_PUBLIC_OLD_API_BASE_URL,
          accessToken,
          { withCredentials: true },
        );
        const data = await customer.getAllSubscriptions();
        setUserSubscriptions(data);
      };
      void effectSetSubscriptions();
    }
  }, [ accessToken ]);

  return (
    <>
      { (subscriptionState === "DEFAULT" && subscriptionId && userSubscriptions?.discountData?.appliesToSubGroups?.[subscriptionId]) ? (
        <SectionWarningCard
          title={title}
          body={
            `You've currently got a ${userSubscriptions.discountData.discount.discount_type === "percentage" ?
              `${userSubscriptions.discountData.discount.value}%` :
              `£${userSubscriptions.discountData.discount.value.toFixed(2)}`} discount on your next box. If you cancel, you'll miss out on it.`
          }
        />
      ) : (
        <>
          { /*
            <SectionWarningCard
            title={blok.title}
            body={"You have a free tote bag in your next box. If you cancel, you’ll miss out on it."}
            />
          */ }
        </>
      ) }
    </>
  );
};

export const StoryBlokWarningCard: FC<IStoryBlokComponent<IStoryBlokWarningCard>> = ({ blok }) => {
  const { accessToken } = useAuth();
  const searchParams = useSearchParams();

  return (
    <>
      <WarningBlock title={blok.title} subscriptionState={blok.subscriptionState} accessToken={accessToken} subscriptionId={searchParams.get("subscriptionId")} />

      <SectionCheckboxEnabledCTA></SectionCheckboxEnabledCTA>
    </>
  );
};
