"use client";

import { Suspense } from "react";

import {
  IStoryBlokComponent,
  IStoryBlokImage,
  IStoryBlokLink,
} from "apps/website/components/page/api.types";
import { Theme } from "apps/website/maps/Theme.map";
import {
  ImageMax,
  getStoryBlokImage,
} from "apps/website/utils/storyblok/image";
import { KatKinProduct } from "@forms/schema";
import { renderStoryBlokRichText } from "apps/website/utils/storyblok/text";
import { useQueryParams } from "apps/website/hooks/useQueryParams";

import SectionOneClickPurchase from "../../section/SectionOneClickPurchase/SectionOneClickPurchase";

export interface IStoryBlokOneClickPurchase {
  title: string;
  theme?: Theme;
  product: KatKinProduct;
  hide_banner_if_product_not_in_basket?: boolean;
  continue_to_path: IStoryBlokLink;
  card_title: string;
  card_images: IStoryBlokImage[];
  card_price_prefix: string;
  card_price: string;
  card_sale_price: string;
  card_tag: string;
  card_subscription_details: string;
  card_add_cta_text: string;
  card_remove_cta_text: string;
  card_footer: string;
  param_discount_code: string;
  param_quantity: string;
  param_cadence: string;
}

export const StoryBlokOneClickPurchase = ({ blok }: IStoryBlokComponent<IStoryBlokOneClickPurchase>) => {
  const { chainQueryParamsToUrl } = useQueryParams();
  return (
    <Suspense>
      <SectionOneClickPurchase
        title={blok.title}
        theme={blok.theme}
        continueToPath={blok.continue_to_path.url}
        product={blok.product}
        hideBannerIfProductNotInBasket={blok.hide_banner_if_product_not_in_basket || false}
        params={{
          discountCode: blok.param_discount_code,
          quantity: blok.param_quantity,
          cadence: blok.param_cadence,
        }}
        card={{
          title: blok.card_title,
          images: blok.card_images.map((image) => ({
            image: getStoryBlokImage(image, true, ImageMax.HALF_CONTAINER_2XL),
            alt: image.alt,
          })),
          pricePrefix: blok.card_price_prefix,
          price: blok.card_price,
          salePrice: blok.card_sale_price,
          tag: blok.card_tag,
          subscriptionDetails: blok.card_subscription_details,
          addCtaText: blok.card_add_cta_text,
          removeCtaText: blok.card_remove_cta_text,
          footer: renderStoryBlokRichText(blok.card_footer, { links: { transformer: chainQueryParamsToUrl } }),
        }}
      />
    </Suspense>
  );
};
