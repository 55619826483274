"use client";

import { Suspense } from "react";

import {
  IStoryBlokComponent,
  IStoryBlokCta,
} from "apps/website/components/page/api.types";
import { getStoryBlokCta } from "apps/website/utils/storyblok/links";
import { Actionable, IStoryBlokLink } from "@forms/schema";
import { DisplayState } from "@/constants/state";

import SectionStickyCTA from "../../section/SectionStickyCTA/SectionStickyCTA";

export interface IStoryBlokStickyCTA {
  text: string;
  cta: [ IStoryBlokCta ];
  show_trustpilot_rating: boolean;
  disabled?: boolean;
  onClick?: () => void;
  state?: DisplayState;
  action?: Actionable;
  action_redirect?: IStoryBlokLink;
}

export const StoryBlokStickyCTA = ({ blok }: IStoryBlokComponent<IStoryBlokStickyCTA>) => (
  <Suspense>
    <SectionStickyCTA
      text={blok?.text}
      cta={getStoryBlokCta(blok?.cta[0])}
      showTrustPilotRating={blok.show_trustpilot_rating || false}
      disabled={blok.disabled}
      onClick={blok.onClick}
      state={blok.state}
    />
  </Suspense>
);
