import { FC, ReactNode, memo } from "react";

import Text from "../Text";
import { Align, AvailableTag, Color } from "../Text.map";

import { titleSizeMap, TTitleSize } from "./TextTitle.map";

export interface ITitleProps {
  id?: string;
  tag?: AvailableTag;
  size?: TTitleSize;
  align?: Align;
  color?: Color;
  children: ReactNode;
}

const TextTitle: FC<ITitleProps> = ({ id, size = 1, align, color, tag = "h2", children }) => (
  <Text
    id={id}
    size={titleSizeMap[size]}
    display="title"
    align={align}
    tag={tag}
    color={color}
  >
    { children }
  </Text>
);

export default memo(TextTitle);
