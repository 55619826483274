import { FC } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import Text from "apps/website/components/base/Text/Text";
import ListItem from "apps/website/components/base/ListItem/ListItem";
import Spacer from "apps/website/components/layout/Spacer/Spacer";
import {
  legacySizeCollectionMap,
} from "apps/website/components/base/Text/Text.map";
import Image, { IImageProps } from "apps/website/components/base/Image/Image";

import { ImageSize, imageSizeClassMap } from "./CondensedListItem.map";

export interface ICondensedListItemProps {
  image?: IImageProps;
  imageSize?: ImageSize;
  title?: string;
  body?: string;
  theme?: Theme;
  disclaimer?: string;
}

const CondensedListItem: FC<ICondensedListItemProps> = ({ image, imageSize = "default", title, body, theme = "default", disclaimer }) => (
  <ListItem component={CondensedListItem.name} direction="column">
    <div data-theme={theme} className={`flex break-inside-avoid-column ${theme !== "default" ? "p-4" : ""} ${themeRootClassMap[theme]}`}>
      <>
        { image && (
          <div className={`flex-none mr-4 ${imageSizeClassMap[imageSize]}`}>
            <Image image={image} alt={title ?? ""}/>
          </div>
        ) }
        <div className={`flex-1 flex flex-col ${body ? "" : "justify-center"}`}>
          <Text tag="h2" display="subtitle" size={legacySizeCollectionMap.titleSm}>{ title }</Text>
          { body && (
            <>
              <Spacer size="sm" />
              <Text size={legacySizeCollectionMap.bodySm}>{ body }</Text>
            </>
          ) }
        </div>
      </>
    </div>
    { disclaimer && (
      <>
        <Spacer size="sm" />
        <Text size={{ default: "4xs", lg: "2xs" }}>{ disclaimer }</Text>
      </>
    ) }
  </ListItem>
);

export default CondensedListItem;
